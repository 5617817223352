import React, { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { withThemeProps } from '@findep/microfronts-core'

import banner_main from '../../assets/images/afi/medios-de-pago-AFI.png'
import banner_sub from '../../assets/images/afi/H-AFI-02.jpg'
import banner_sub_in from '../../assets/images/afi/in-afi.jpg'

import banner_main_mob from '../../assets/images/afi/medios-de-pago-AFI_mob.png'
import banner_sub_mob from '../../assets/images/afi/H-AFI-02-mob.jpg'
import banner_sub_in_mob from '../../assets/images/afi/in-afi_mob.jpg'

function ImageResponsive({title, lang, ...otherProps}) {
    

    const [loadImg, setLoadimg] = useState(banner_main_mob)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {        
        async function initValues() {
                setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
            switch(title){
                case "main":default: isMobile ? setLoadimg(banner_main_mob) : setLoadimg(banner_main); break;
                case "submain": isMobile ? lang === "esES" ? setLoadimg(banner_sub_mob) : setLoadimg(banner_sub_in_mob) : lang === "esES" ? setLoadimg(banner_sub) : setLoadimg(banner_sub_in); break;
            }
        }
        initValues()
        
      }, [])

    const cssImg = css`
        width: 100%; 
        height: auto;
    `
    const cssImgMob = css`
        width: 350px; 
        height: 350px;
    `

    return (
        <img src={loadImg} rel="nofollow noopener noreferrer" alt={`in-icon-${title}`} css={isMobile ? cssImgMob : cssImg}/>
    )
}

ImageResponsive.defaultProps = {
    lang: 'es',
}

export default withThemeProps(ImageResponsive, 'VDNPImageResponsive')
