import { css } from '@emotion/react'
import React from 'react'
import { Component, memo } from 'react';
import {Container,Box,Grid} from '@material-ui/core';
import { withThemeProps } from '@findep/microfronts-core'

import SimuladorCard from '../pages/SimuladorCard'
import ImageResponsive from './ImageResponsive'
import ListHome from './ListHome';

const title_pry = css`
    color: #54595F;
    font-family: "Poppins", Sans-serif;    
    font-size: 30px;
    font-weight: 600;
`
const subtitle_pry = css`
    color: #928d85;
    font-family: "Poppins", Sans-serif;    
    font-size: 16px;
    font-weight: 400;
`
class Simulador extends Component {    
   
    render() {
        const { 
            slogan,
            subtitle,
            textAplicapri="Requisitos para solicitar tu préstamo:",
            textDebito="Identificación oficial vigente, Comprobante reciente de ingresos y de domicilio",
            textPagos="Aplica en línea",
            textAcceso="Recibe tu préstamo directo en tu cuenta de débito.",
            textPenalizacion="La comodidad para hacer tus pagos",
            textAnticipados="Acceso a tu cuenta las 24 horas del día desde donde te encuentres a través de nuestra APP Apoyo Financiero Mobile.",   
        } = this.props

        return (
            <Container justify="center">
                <br/><br/>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <Box component="span" m={1} >
                            <span css={title_pry}> {slogan('AFI')}</span><br/>
                            <span css={subtitle_pry}>{subtitle}</span><br /><br/>
                            <SimuladorCard pageContext={this.props.pageContext} />
                        </Box>
                    </Grid>                    
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={4}>
                        <ImageResponsive title="main" />                        
                    </Grid>

                    <ListHome 
                        textAplicapri={textAplicapri}
                        textDebito={textDebito}
                        textPagos={textPagos}
                        textAcceso={textAcceso}
                        textPenalizacion={textPenalizacion}
                        textAnticipados={textAnticipados}
                        lang={this.props.pageContext?.locale}
                    />                     

                </Grid>
            </Container>
        );
    }
}

Simulador.defaultProps = {
    slogan: ()=> (<>El préstamo que necesitas,  fácil, rápido y seguro.</>),
    subtitle:'Nuestras soluciones inteligentes ayudan a fortalecer tus finanzas y a darte confianza.'
}

export default memo(withThemeProps(Simulador, 'VDNPSimulador'));