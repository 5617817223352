import React from "react"
import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import Simulador from '../components/pages/SimuladorAfi'

const SimuladorPage = ({ pageContext, location }) => {
  return (
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      pageContext={pageContext}
      companyName={pageContext.company}
      location={location}
      render={() => (
        <>
            <SEO title={pageContext.title} />          
            <Simulador pageContext={pageContext} />          
        </>
      )}
    />
  )
}

export default SimuladorPage
