import React, { useState, useEffect } from 'react'
import { withThemeProps } from '@findep/microfronts-core'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import {Grid} from '@material-ui/core';
import ImageResponsive from './ImageResponsive'


function ListHome({lang,textAplicapri,textDebito,textPagos,textAcceso,textPenalizacion,textAnticipados}) {
    
     const [isMobile, setIsMobile] = useState(false)
    
        useEffect(() => {        
            async function initValues() {
                    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));                
            }
            initValues()            
          }, [])

    const listText=()=>{

        return (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                    <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                    <ListItemText primary={textAplicapri} secondary={textDebito}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                    <ListItemText primary={textPagos} secondary={textAcceso}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                    <ListItemText primary={textPenalizacion} secondary={textAnticipados}/>
                </ListItem>
            </List>
        )
    }

    return (
        isMobile ? (
                     <>              
                        <Grid item xs={12} >
                            {listText()}
                        </Grid>
                        <Grid item xs={12} >
                            <ImageResponsive title="submain" lang={lang}/>                           
                        </Grid>                                                                                      
                    </>
        ):(
            <>
                <Grid item xs={12} sm={1}></Grid>
                <Grid item xs={12} sm={5}>
                    <ImageResponsive title="submain" lang={lang}/>
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
                <Grid item xs={12} sm={5}>
                    {listText()}
                </Grid>
            </>
        )  
    )
}

ListHome.defaultProps = {
    lang: 'es',
}

export default withThemeProps(ListHome, 'VDNPListHome')
